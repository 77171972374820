import styled from 'styled-components';
import ButtonLink from 'components/atoms/ButtonLink';
import Link from 'components/atoms/Link';
import { heading } from 'constants/meta';

const Wrapper = styled.div`
  border-radius: 0.25em;
  border: 0.2em solid ${({ theme }) => theme.colors.neutral4};
  margin: 2em 0;
  padding: 1em;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding: 2em 3em;
  }
`;

const Title = styled.h1`
  font-size: 1.6em;
  font-weight: 600;
  margin: 0.3em 0;
`;

const Text = styled.p`
  margin: 0.6em 0;
  padding: 0;
  line-height: 1.8;
  text-align: justify;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledButtonLink = styled(ButtonLink)`
  margin: 0.3em;
`;

const AboutCompany = () => (
  <Wrapper>
    <Title>{heading.home}</Title>
    <Text>
      Интернет-магазин "Золотая Камея" - представитель компаний JINDELI и JINSHIDA, ведущих мировых
      производителей одноразовых опок. Предлагаем ювелирам{' '}
      <Link to="/catalog/opoki-i-voskovki">опоки по 12 (двенадцати!) каталогам</Link> – это более
      8000 изделий. Как только на фабриках появляется новый каталог, он сразу же отгружается нам.
      Преимущество перед конкурентами - наличие (не менее 97,6%) полугодового запаса предлагаемых
      опок у нас на складе. Текущий остаток на складе – 230 тысяч штук. Доставка по всей России.
    </Text>
    <Text>
      Импортируем <Link to="/catalog/Ruchnoj-instrument">ювелирный инструмент</Link> и{' '}
      <Link to="/catalog">оборудование для ювелиров</Link> ведущих мировых производителей из Италии,
      Турции, Китая, ОАЭ, Польши, Украины и других стран. Являемся представителями компаний "Сапфир"
      и "Юмо".
    </Text>
    <Text>
      Большой выбор <Link to="/catalog/Vstavki">синтетических и натуральных ювелирных вставок</Link>{' '}
      - от стандартных форм, цветов и размеров до фантазийных (текущий остаток на складе более 3300
      видов камней). Работаем напрямую с 17 фабриками производителями синтетических камней (Италия,
      Турция, Китай). В России поставляем вставки на ведущие ювелирные производства (в том числе и
      для литья с камнями).
    </Text>
    <Text>
      Предлагаем ювелирам восковые модели компании Гелис.{' '}
      <Link to="/catalog/Voskovki">Восковки</Link> по каталогу (а это около 2500 моделей) – в
      наличии!
    </Text>
    <Text>
      А также <Link to="/catalog/Furnitura">ювелирная фурнитура</Link>: золотые и серебрянные
      карабины и шпрингельные замки – отличное качество за реальные деньги (для заказа необходимо
      прислать копию карты постановки на спецучет в Пробирной Палате РФ).
    </Text>
    <Buttons>
      <StyledButtonLink to="/about" primary>
        Подробнее о компании
      </StyledButtonLink>
      <StyledButtonLink to="/contacts" neutral>
        Контакты
      </StyledButtonLink>
    </Buttons>
  </Wrapper>
);

export default AboutCompany;
