import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import ButtonLink from 'components/atoms/ButtonLink';
import slide0jpg from './slide-0.jpg';
import slide0webp from './slide-0.webp';
import slide0avif from './slide-0.avif';
import slide1jpg from './slide-1.jpg';
import slide1webp from './slide-1.webp';
import slide1avif from './slide-1.avif';
import slide2jpg from './slide-2.jpg';
import slide2webp from './slide-2.webp';
import slide2avif from './slide-2.avif';
import slide3jpg from './slide-3.jpg';
import slide3webp from './slide-3.webp';
import slide3avif from './slide-3.avif';

const DotsWrapper = styled.div`
  position: absolute;
  top: 1em;
  right: 0.5em;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 2;
`;

const DotWrapper = styled.button`
  display: block;
  width: 1.3em;
  height: 1.3em;
  margin: 0 0.5em;
  padding: 0.25em;
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;
  opacity: ${({ active }) => (active ? '0.75' : '0.25')};

  &:hover {
    outline: none;
    opacity: 1;
  }

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 100%;
  }
`;

const Wrapper = styled.div`
  border-radius: 0.25em;
  border: 1px solid ${({ theme }) => theme.colors.neutral4};
  width: 100%;
  position: relative;
  user-select: none;
  overflow: hidden;
`;

const Slides = styled.div`
  width: 100%;
  height: 25em;
  padding-top: 42%;
  position: relative;
  overflow: hidden;
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ active }) => (active ? '1' : '0')};
  z-index: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 500ms linear;
`;

const Picture = styled.picture``;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
`;

const Description = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.642em 2.714em;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: -1;
  }
`;

const BlurWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.neutral3};
  margin: -8em;
  z-index: -1;
  opacity: 0.5;

  & > * {
    display: none;
  }

  @supports (filter: blur(3em)) {
    filter: blur(3em);

    & > * {
      display: block;
    }
  }
`;

const BlurImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -0.5em;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TitleAndTextWrapper = styled.div`
  padding: 1em 0.5em;
  min-width: 15em;
  max-width: 30em;
  flex: 1 1 0;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 0.5em;
`;

const Text = styled.div``;

const ButtonWrapper = styled.div`
  flex: none;
  padding: 1em 0.5em;
`;

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [indexes, setIndexes] = useState({ 0: true });

  useEffect(() => {
    let timeoutID;
    timeoutID = setTimeout(() => {
      const newSlide = (currentSlide + 1) % 4;

      if (!indexes[newSlide]) {
        setIndexes({ ...indexes, [newSlide]: true });
      }

      setCurrentSlide(newSlide);
    }, 4000);

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [currentSlide]);

  function goTo(index) {
    return () => {
      if (!indexes[index]) {
        setIndexes({ ...indexes, [index]: true });
      }
      setCurrentSlide(index);
    };
  }

  return (
    <Wrapper>
      <Helmet>
        <link rel="preload" as="image" href={slide0avif} type="image/avif" />
      </Helmet>
      <Slides>
        <Slide active={currentSlide === 0}>
          {indexes[0] && (
            <Picture>
              <source srcSet={slide0avif} type="image/avif" />
              <source srcSet={slide0webp} type="image/webp" />
              <Image src={slide0jpg} alt="Слайд №0 (Ударный электрогравер FOREDOM + подарок!)" />
            </Picture>
          )}
          <Description>
            {indexes[0] && (
              <BlurWrapper aria-hidden="true">
                <Picture>
                  <source srcSet={slide0avif} type="image/avif" />
                  <source srcSet={slide0webp} type="image/webp" />
                  <BlurImage src={slide0jpg} />
                </Picture>
              </BlurWrapper>
            )}
            <Row>
              <TitleAndTextWrapper>
                <Title>Товар недели!</Title>
                <Text>Ударный электрогравер FOREDOM! Купи электрогравер и получи подарок!</Text>
              </TitleAndTextWrapper>
              <ButtonWrapper>
                <ButtonLink primary to="/catalog/Bormashiny/cjs87k02i43hm0c9410brzzpe">
                  Получить подарок!
                </ButtonLink>
              </ButtonWrapper>
            </Row>
          </Description>
        </Slide>
        <Slide active={currentSlide === 1}>
          {indexes[1] && (
            <Picture>
              <source srcSet={slide1avif} type="image/avif" />
              <source srcSet={slide1webp} type="image/webp" />
              <Image src={slide1jpg} alt="Слайд №1 (Ювелирные вставки)" />
            </Picture>
          )}
          <Description>
            {indexes[1] && (
              <BlurWrapper aria-hidden="true">
                <Picture>
                  <source srcSet={slide1avif} type="image/avif" />
                  <source srcSet={slide1webp} type="image/webp" />
                  <BlurImage src={slide1jpg} />
                </Picture>
              </BlurWrapper>
            )}
            <Row>
              <TitleAndTextWrapper>
                <Title>Ювелирные вставки</Title>
                <Text>
                  Компания «Золотая Камея» предлагает большой выбор ювелирных вставок разнообразных
                  форм, цветов, материалов и размеров
                </Text>
              </TitleAndTextWrapper>
              <ButtonWrapper>
                <ButtonLink primary to="/catalog/Vstavki">
                  Подобрать вставки
                </ButtonLink>
              </ButtonWrapper>
            </Row>
          </Description>
        </Slide>
        <Slide active={currentSlide === 2}>
          {indexes[2] && (
            <Picture>
              <source srcSet={slide2avif} type="image/avif" />
              <source srcSet={slide2webp} type="image/webp" />
              <Image src={slide2jpg} alt="Слайд №2 (Одноразовые опоки)" />
            </Picture>
          )}
          <Description>
            {indexes[2] && (
              <BlurWrapper aria-hidden="true">
                <Picture>
                  <source srcSet={slide2avif} type="image/avif" />
                  <source srcSet={slide2webp} type="image/webp" />
                  <BlurImage src={slide2jpg} />
                </Picture>
              </BlurWrapper>
            )}
            <Row>
              <TitleAndTextWrapper>
                <Title>Одноразовые опоки</Title>
                <Text>
                  Вам доступны 12 каталогов одноразовых опок для изготовления ювелирных изделий, а
                  это более 8 тысяч опок <strong>от 15 р.</strong>
                </Text>
              </TitleAndTextWrapper>
              <ButtonWrapper>
                <ButtonLink primary to="/catalog/opoki-i-voskovki">
                  Перейти в каталог
                </ButtonLink>
              </ButtonWrapper>
            </Row>
          </Description>
        </Slide>
        <Slide active={currentSlide === 3}>
          {indexes[3] && (
            <Picture>
              <source srcSet={slide3avif} type="image/avif" />
              <source srcSet={slide3webp} type="image/webp" />
              <Image src={slide3jpg} alt="Слайд №2 (Ювелирный инструмент)" />
            </Picture>
          )}
          <Description>
            {indexes[3] && (
              <BlurWrapper aria-hidden="true">
                <Picture>
                  <source srcSet={slide3avif} type="image/avif" />
                  <source srcSet={slide3webp} type="image/webp" />
                  <BlurImage src={slide3jpg} />
                </Picture>
              </BlurWrapper>
            )}
            <Row>
              <TitleAndTextWrapper>
                <Title>Ювелирный инструмент</Title>
                <Text>Компания «Золотая Камея» занимается продажей ювелирных инструментов</Text>
              </TitleAndTextWrapper>
              <ButtonWrapper>
                <ButtonLink primary to="/catalog">
                  Перейти в каталог
                </ButtonLink>
              </ButtonWrapper>
            </Row>
          </Description>
        </Slide>
      </Slides>
      <DotsWrapper>
        <DotWrapper
          aria-label={`Перейти к слайду №0`}
          active={currentSlide === 0}
          onClick={goTo(0)}
        />
        <DotWrapper
          aria-label={`Перейти к слайду №1`}
          active={currentSlide === 1}
          onClick={goTo(1)}
        />
        <DotWrapper
          aria-label={`Перейти к слайду №2`}
          active={currentSlide === 2}
          onClick={goTo(2)}
        />
        <DotWrapper
          aria-label={`Перейти к слайду №3`}
          active={currentSlide === 3}
          onClick={goTo(3)}
        />
      </DotsWrapper>
    </Wrapper>
  );
};

export default Carousel;
