import Icon from 'react-icon-base';

const TruckIcon = (props) => (
  <Icon viewBox="0 0 37 23" {...props} height="1em" width="1.6em">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6744 8.00383L27.4221 2.83351C27.088 2.50529 26.6381 2.31913 26.1702 2.31913L23.5196 2.31907L23.5196 1.3302C23.5196 0.594242 22.9226 3.47204e-05 22.1831 3.544e-05L6.88472 -5.46562e-05C6.14962 -3.57454e-05 5.54824 0.594221 5.54824 1.3303L5.54827 1.53861L0.124769 2.16082L9.73496 3.40646L1.11526e-05 4.36904L9.70066 5.74183L2.84155e-05 6.57899L5.54858 7.62062L5.5482 10.2343L33.2001 10.2343L33.2002 9.26318C33.2001 8.78872 33.0086 8.33647 32.6744 8.00383ZM33.2011 13.9148L33.201 10.9696L5.54918 10.9696L5.54916 13.9146C5.54921 14.6494 6.14753 15.2449 6.88563 15.2449L8.67657 15.2449C8.93487 13.4802 10.4629 12.1232 12.3028 12.1232C14.1427 12.1232 15.6708 13.4801 15.9292 15.2449L23.1774 15.2449C23.4358 13.4801 24.9639 12.1232 26.8037 12.1232C28.6481 12.1232 30.1761 13.48 30.4345 15.245L31.8645 15.245C32.6027 15.245 33.2011 14.6494 33.2011 13.9148ZM12.3019 13.0102C10.7694 13.0102 9.53098 14.2473 9.53096 15.7728C9.53099 17.2936 10.7694 18.5309 12.3019 18.5309C13.8344 18.5308 15.0729 17.2937 15.0729 15.7728C15.0729 14.2473 13.8344 13.0101 12.3019 13.0102ZM12.3019 17.1517C11.5357 17.1517 10.9164 16.5309 10.9164 15.7726C10.9164 15.01 11.5356 14.3935 12.3019 14.3936C13.0682 14.3936 13.6874 15.0099 13.6874 15.7726C13.6874 16.5309 13.0682 17.1517 12.3019 17.1517ZM26.8073 13.0102C25.2748 13.0103 24.0319 14.2474 24.0319 15.7728C24.0319 17.2936 25.2749 18.5309 26.8073 18.5308C28.3353 18.5308 29.5782 17.2936 29.5783 15.7728C29.5782 14.2474 28.3353 13.0102 26.8073 13.0102ZM25.4218 15.7726C25.4218 16.531 26.041 17.1518 26.8073 17.1518C27.5691 17.1518 28.1928 16.5309 28.1927 15.7727C28.1927 15.01 27.569 14.3936 26.8073 14.3936C26.041 14.3936 25.4218 15.0101 25.4218 15.7726ZM25.3015 8.17672L30.3847 8.17675C30.5717 8.17669 30.6653 7.94618 30.5273 7.81757L26.4154 3.911C26.3752 3.87551 26.3218 3.85331 26.2683 3.85329L25.3016 3.85326C25.1857 3.8533 25.0922 3.94645 25.0922 4.06176L25.0922 7.96835C25.0922 8.08365 25.1857 8.17676 25.3015 8.17672Z"
        transform="translate(0.476685 3.19604) rotate(-5)"
      />
    </g>
  </Icon>
);

export default TruckIcon;
