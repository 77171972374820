import styled from 'styled-components';
import Feature from './Feature';
import TruckIcon from './TruckIcon';
import FeedbackIcon from './FeedbackIcon';
import EyeIcon from './EyeIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  overflow-x: auto;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: column;
    overflow: hidden;
    margin: -0.5em 0;
  }
`;

const StyledFeature = styled(Feature)`
  margin: 0 0.5em;
  width: 70%;
  flex: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0.5em 0;
    width: 100%;
  }
`;

const Features = () => (
  <Wrapper>
    <StyledFeature
      title="Большой ассортимент"
      text="Товарные позиции постоянно пополняются и расширяются"
      icon={FeedbackIcon}
    />
    <StyledFeature
      title="Быстрая доставка"
      text="Быстро формируем заказ и отправляем на следующей день"
      icon={TruckIcon}
    />
    <StyledFeature
      title="Низкие цены"
      text="Наша наценка минимальна. Мы отслеживаем цены у конкурентов и делаем лучшее предложение на товар"
      icon={EyeIcon}
    />
  </Wrapper>
);

export default Features;
