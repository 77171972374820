import styled from 'styled-components';
import ItemsList from 'components/molecules/ItemsList';

const Wrapper = styled.div`
  margin-top: 2.5em;
`;

const Title = styled.h3``;

const RecommendedItems = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Рекомендуемые товары</Title>
      <ItemsList cols={[2, 3, 3]} items={items} />
    </Wrapper>
  );
};

export default RecommendedItems;
