import styled from 'styled-components';
import SaleCard from 'components/molecules/SaleCard';
import ButtonLink from 'components/atoms/ButtonLink';

const Wrapper = styled.div`
  margin-top: 2.5em;
`;

const Title = styled.h3``;

const SalesListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  overflow-x: auto;
  width: 100%;
`;

const StyledSaleCard = styled(SaleCard)`
  margin: 0 0.5em;
  width: 70%;
  flex: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: calc((100% - 2em) / 3);
  }
`;

const LatestSales = ({ sales }) => {
  if (sales.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Акции и скидки</Title>
      <SalesListWrapper>
        {sales.map((sale) => (
          <StyledSaleCard sale={sale} key={sale.id} hideButton hideLabel />
        ))}
      </SalesListWrapper>
      <ButtonLink to="/sales" inline neutral>
        Все акции
      </ButtonLink>
    </Wrapper>
  );
};

export default LatestSales;
