import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-apollo';
import { metaDescription, metaKeywords, title } from 'constants/meta';
import homePageQuery from 'graphql/queries/homePage.graphql';
import MainTemplate from 'components/templates/MainTemplate';
import CatalogMenuContext from 'components/molecules/CatalogMenu/Context';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import Container from './Container';

const HomePage = () => {
  const catalogMenuBubbleRef = useRef(null);
  const { loading, data, error } = useQuery(homePageQuery);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorLoadingPage />;
  }

  return (
    <CatalogMenuContext.Provider value={{ ref: catalogMenuBubbleRef, isMenuShownOnDesktop: true }}>
      <MainTemplate>
        <Helmet defaultTitle={title.home}>
          <meta name="keywords" content={metaKeywords.home} />
          <meta name="description" content={metaDescription.home} />
        </Helmet>
        <Container data={data} />
      </MainTemplate>
    </CatalogMenuContext.Provider>
  );
};

export default HomePage;
