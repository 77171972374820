import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  padding: 0.75em;
`;

const Title = styled.div`
  font-size: 1.14em;
  font-weight: 600;
`;

const Text = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral3};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.3em;
`;

const Icon = styled(({ component: Component, ...rest }) => <Component {...rest} />)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5em;
  flex: none;
  align-self: flex-start;
  margin-left: 0.5em;
`;

const Feature = ({ title, text, icon, className }) => (
  <Wrapper className={className}>
    <Row>
      <Title>{title}</Title>
      <Icon component={icon} />
    </Row>
    <Text>{text}</Text>
  </Wrapper>
);

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default Feature;
