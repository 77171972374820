import { useState, useEffect } from 'react';
import styled from 'styled-components';
import CatalogMenuContext from 'components/molecules/CatalogMenu/Context';
import { ANIMATION_DURATION } from 'components/molecules/CatalogMenu/Bubble';
import Features from './Features';
import News from './News';

const Wrapper = styled.aside`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin-top: ${({ catalogMenuBubbleHeight }) => catalogMenuBubbleHeight}px;
    transition: margin-top 0.3s;
  }
`;

const LeftColumn = ({ className, data }) => {
  const [catalogMenuBubbleHeight, setCatalogMenuBubbleHeight] = useState(0);
  const { isMenuShownOnDesktop, ref } = CatalogMenuContext.useCatalogMenuContext();

  function resetCatalogMenuBubbleHeight() {
    let newHeight = 0;

    if (isMenuShownOnDesktop && ref.current) {
      newHeight = ref.current.clientHeight;
    }

    if (newHeight !== catalogMenuBubbleHeight) {
      setCatalogMenuBubbleHeight(newHeight);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resetCatalogMenuBubbleHeight, true);
    resetCatalogMenuBubbleHeight();
    return () => {
      window.removeEventListener('resize', resetCatalogMenuBubbleHeight, true);
    };
  }, []);

  useEffect(() => {
    if (!isMenuShownOnDesktop || !ref.current) {
      setCatalogMenuBubbleHeight(0);
      return;
    }

    setTimeout(() => {
      resetCatalogMenuBubbleHeight();
    }, ANIMATION_DURATION);
  }, [isMenuShownOnDesktop, ref]);

  return (
    <Wrapper className={className} catalogMenuBubbleHeight={catalogMenuBubbleHeight}>
      <Features />
      <News news={data.latestNewsConnection.edges.map((edge) => edge.node)} />
    </Wrapper>
  );
};

export default LeftColumn;
