import styled from 'styled-components';
import NewsCard from 'components/pages/NewsesPage/NewsCard';
import ButtonLink from 'components/atoms/ButtonLink';

const Wrapper = styled.div`
  margin-top: 2.5em;
`;

const Title = styled.h3``;

const NewsListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  overflow-x: auto;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: column;
    overflow: hidden;
    margin: -0.5em 0 0.5em;
  }
`;

const StyledNewsCard = styled(NewsCard)`
  margin: 0 0.5em;
  width: 70%;
  flex: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0.25em 0;
    width: 100%;
  }
`;

const News = ({ news }) => {
  if (news.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Свежие новости</Title>
      <NewsListWrapper>
        {news.map((n) => (
          <StyledNewsCard news={n} key={n.id} alternative />
        ))}
      </NewsListWrapper>
      <ButtonLink to="/news" inline neutral>
        Все новости
      </ButtonLink>
    </Wrapper>
  );
};

export default News;
