import Icon from 'react-icon-base';

const EyeIcon = (props) => (
  <Icon viewBox="0 0 33 22" {...props} height="1em" width="1.5em">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8689 8.11263C25.9336 3.10839 20.4775 -5.63364e-05 14.6297 3.83365e-05C8.78195 1.67231e-05 3.32584 3.10846 0.390614 8.11264L-1.98102e-05 8.77779L0.390609 9.44327C3.32526 14.4481 8.78138 17.556 14.6298 17.556C20.4775 17.556 25.9331 14.4481 28.8688 9.44338L29.26 8.77767L28.8689 8.11263ZM20.2588 6.50821C20.2588 7.12224 20.0902 7.69591 19.7997 8.19189C19.2081 9.20363 18.1037 9.8867 16.8338 9.88675C14.9427 9.88672 13.4094 8.37379 13.4094 6.50829C13.4094 5.03136 14.3711 3.77936 15.7083 3.31957C16.0607 3.19833 16.4384 3.12918 16.8338 3.12922C18.7254 3.12916 20.2587 4.64222 20.2588 6.50821ZM11.743 10.1035C11.0232 10.1036 10.4311 10.623 10.3196 11.3019C10.3078 11.3773 10.2964 11.4528 10.2964 11.531C10.2964 12.3194 10.944 12.9585 11.743 12.9584C11.8122 12.9584 11.8796 12.9521 11.9454 12.9413C12.0135 12.9302 12.08 12.9144 12.1455 12.8957C12.7473 12.7224 13.1896 12.1811 13.1896 11.5316C13.1896 10.7426 12.542 10.1036 11.743 10.1035ZM3.13943 8.77772C4.31774 7.01372 5.8882 5.5715 7.69307 4.52843C7.04768 5.65861 6.67538 6.96009 6.67535 8.34708C6.67534 10.3556 7.45442 12.1827 8.72106 13.5682C6.48194 12.5129 4.53593 10.8702 3.13943 8.77772ZM22.5022 8.3477C22.5022 10.3917 21.6962 12.2482 20.3891 13.6417C22.6908 12.5895 24.6917 10.9176 26.1195 8.77776C24.9142 6.97237 23.2974 5.50396 21.4381 4.4556C22.1105 5.60292 22.5022 6.92983 22.5022 8.3477Z"
        transform="translate(0.99823 3.65918) rotate(-5)"
      />
    </g>
  </Icon>
);

export default EyeIcon;
