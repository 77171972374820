import Icon from 'react-icon-base';
import styled from 'styled-components';

const FeedbackIcon = (props) => (
  <Icon viewBox="0 0 30 30" {...props} width="1.2em" height="1.2em">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25841 5.59154e-05L22.5838 4.61327e-05C23.8288 3.2126e-05 24.8421 1.0332 24.8422 2.30379L24.8422 17.2782C24.8422 18.549 23.8288 19.5821 22.5838 19.5821L6.44429 19.5821L0.963763 25.1728C0.855659 25.2831 0.711222 25.3415 0.564543 25.3416C0.491756 25.3416 0.417932 25.3275 0.348431 25.2977C0.137847 25.2088 1.37151e-05 24.9984 -1.93057e-05 24.7656L1.52788e-05 2.30381C-2.48573e-05 1.03323 1.01344 -5.16837e-05 2.25841 5.59154e-05ZM6.21057 9.79107C6.21054 13.2845 8.99706 16.1265 12.421 16.1265C15.845 16.1264 18.6316 13.2844 18.6316 9.79099C18.6316 6.29772 15.8451 3.45566 12.4211 3.4557C8.9971 3.45562 6.21047 6.29766 6.21057 9.79107Z"
        transform="translate(1.19659 3.07654) rotate(-5)"
      />
      <path
        d="M1.12917 2.30374C1.7528 2.30374 2.25834 1.78803 2.25834 1.15187C2.25834 0.515709 1.7528 0 1.12917 0C0.505547 0 0 0.515709 0 1.15187C0 1.78803 0.505547 2.30374 1.12917 2.30374Z"
        transform="translate(10.7863 8.40002) rotate(-5)"
      />
      <path
        d="M1.12917 2.30374C1.7528 2.30374 2.25834 1.78803 2.25834 1.15187C2.25834 0.515709 1.7528 0 1.12917 0C0.505547 0 0 0.515709 0 1.15187C0 1.78803 0.505547 2.30374 1.12917 2.30374Z"
        transform="translate(15.2858 8.00635) rotate(-5)"
      />
      <path
        d="M6.34975 0.0203622C6.05312 -0.0557217 5.74216 0.130231 5.66387 0.442092C5.64402 0.519934 5.16653 2.34474 3.38785 2.34474C1.60917 2.34474 1.13168 0.519879 1.11183 0.442092C1.03466 0.129626 0.723687 -0.0626434 0.428122 0.0186592C0.124882 0.0970504 -0.0582057 0.415229 0.0167972 0.728794C0.249481 1.693 1.25294 3.51671 3.38785 3.51671C5.52276 3.51671 6.52622 1.693 6.75885 0.728794C6.83385 0.416328 6.65077 0.100456 6.34975 0.0203622Z"
        transform="translate(11.0811 11.9016) rotate(-5)"
      />
    </g>
  </Icon>
);

export default styled(FeedbackIcon)`
  margin: -0.1em 0;
`;
