import styled from 'styled-components';
import Carousel from './Carousel';
import AboutCompany from './AboutCompany';
import RecommendedItems from './RecommendedItems';
import LatestSales from './LatestSales';
import LeftColumn from './LeftColumn';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    margin: 0 -0.625em;
  }
`;

const StyledLeftColumn = styled(LeftColumn)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 25%;
    flex: none;
    order: 1;
    padding: 0 0.625em;
  }
`;

const RightColumn = styled.main`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 75%;
    flex: none;
    order: 2;
    padding: 0 0.625em;
  }
`;

const Container = ({ data }) => (
  <>
    <Wrapper>
      <RightColumn>
        <Carousel />
        <LatestSales sales={data.latestSalesConnection.edges.map((edge) => edge.node)} />
        <RecommendedItems items={data.recommendedItems} />
      </RightColumn>
      <StyledLeftColumn data={data} />
    </Wrapper>
    <AboutCompany />
  </>
);

export default Container;
